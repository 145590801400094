import React, { Component, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const LogOut = () => {
  const history = useNavigate()
  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    history('/')
  }, [])
  return <></>
}

class App extends Component {
  render() {
    return (
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/logout" name="Logout Page" element={<LogOut />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    )
  }
}

export default App
