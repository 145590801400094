/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function RequestInterceptor() {
  const navigate = useNavigate()

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(null, (error) => {
      if (error.response?.status === 401) {
        navigate('/login')
      }
      return Promise.reject(error)
    })

    return () => {
      axios.interceptors.response.eject(interceptorId)
    }
  }, [])

  // No need to render anything here!
  return <></>
}

export default RequestInterceptor
